import React from "react"
import NoheaderFooter from "../../../../components/Noheaderfooter"
import SEO from "../../../../components/seo"
import SavingsMobile from "../../../../components/body/pages/mobile/ng/legal/savings"

const TermsAndConditionSavingsPage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/savings"}
      title="Kuda Save - Pockets - Spend+Save - Terms and Conditions"
      description="Read and accept the terms and conditions of using Kuda Save - saving features including Spend+Save and Pockets - As You Want, Frequently, Fixed Deposit."
    />
    <SavingsMobile />
  </NoheaderFooter>
)

export default TermsAndConditionSavingsPage
